<template>
    <div class="flex flex-col items-center pl-20 pr-20">
        <div class="w-full 1280:w-1160">
            <div class="flex items-center pr-20 pl-20">
                <img :src="IMGS.arrow_back" class=" block mr-5">
                <span class="block text-sm text-accent cursor-pointer" @click="onBack">назад</span>
            </div>
            <screen-wrapper>
            <template v-slot:content>
                <section class="font-light details-format">
                    <p class="text-center font-arialBold"><strong>ПРАВИЛА УЧАСТИЯ ВО ВСЕРОССИЙСКОМ ПРОЕКТЕ «СВЕРХНОВАЯ»</strong></p>
                    <p class="text-center font-arialBold"><strong>(далее – Правила)</strong></p>
                    <p>Для участия в конкурсе вам необходимо ознакомиться и согласиться с приведенными ниже правилами. Регистрируясь на сайте, вы соглашаетесь со всеми пунктами Правил.</p>

                    <p><span class="font-arialBold">1. Основные понятия и определения:</span></p>
                    <p><span class="font-arialBold">1.1. Конкурс, Проект – Всероссийский проект «Сверхновая»;</span></p>
                    <p><span class="font-arialBold">1.2. Организатор Конкурса – ООО «Хьюман Филмс» (ИНН 7725498160, КПП 770501001; ОГРН 1187746861884) по заказу ПАО МТС (ИНН 7740000076, КПП 770901001; ОГРН 1027700149124).</span></p>
                    <p><span class="font-arialBold">Организатор обеспечивает проведение Конкурса и выдачу Главного Приза Победителю Конкурса.</span></p>
                    <p><span class="font-arialBold">1.3. Участница Конкурса – физическое дееспособное лицо (лицо женского пола), достигшее 18-летнего возраста, постоянно проживающее на территории РФ, и совершившее действия, необходимые для участия в Конкурсе согласно настоящим Правилам.</span></p>
                    <p><span class="font-arialBold">1.4. Заявка - информация, предоставленная Организатору при заполнении и отправке электронной регистрационной формы (Анкеты) на официальном сайте Конкурса в соответствии с п. 2.6. настоящих Правил. Неполные, не соответствующие требованиям настоящих Правил Заявки Организатором не рассматриваются и Заявками не признаются.</span></p>
                    <p><span class="font-arialBold">1.5. Победитель 1 – участница, которая в соответствии с настоящими Правилами будет признана Победителем Конкурса, и которой будет предоставлено право на получение Главного приза Конкурса.</span></p>
                    <p><span class="font-arialBold">1.6. Победитель 2 – участница, которая в соответствии с настоящими Правилами будет признана Победителем 2 Конкурса, и которой будет предоставлено право на получение Приза зрительских симпатий.</span></p>
                    <p><span class="font-arialBold">1.7. Главный приз – Денежная сумма в размере 15 384 615 руб. (пятнадцать миллионов триста восемьдесят четыре тысячи шестьсот пятнадцать рублей 00 копеек). При получении Главного приза налоговым агентом (Организатор) с суммы полученного дохода будет удержан налог на доходы физических лиц по ставке 35% (на основании п. 2 ст. 224 НК РФ).</span></p>
                    <p><span class="font-arialBold">Сумма, подлежащая выплате Победителю Конкурса после уплаты НДФЛ, составляет 10 000 000 руб. (десять миллионов рублей 00 копеек).</span></p>
                    <p><span class="font-arialBold">1.8. Официальный сайт Конкурса: www.sverxnovaya.mts.ru</span></p>
                    <p><span class="font-arialBold">1.9. Официальная телепрограмма проекта – аудиовизуальное произведение – реалити-шоу, создаваемое Организатором.</span></p>
                    <p><span class="font-arialBold">1.10. Организатор Конкурса оставляет за собой право дополнять правила конкурса иными призами, а также вводить дополнительные номинации с внесением соответствующих изменений в настоящие Правила.</span></p>
                    <p><span class="font-arialBold">1.11. Общий период проведения Конкурса с учетом выдачи призов: с 31 июля 2023 года по 25 декабря 2023 года.</span></p>

                    <p><span class="font-arialBold">2. Условия проведения Всероссийского проекта «Сверхновая»:</span></p>
                    <p><span class="font-arialBold">2.1. Подача заявок производится на официальном сайте Конкурса в период с 31 июля 2023 года по 28 августа 2023 года.<br /> Претендентам необходимо заполнить заявку и загрузить свою видео-визитку.</span></p>
                    <p><span class="font-arialBold">2.2. Отбор заявок – проводится Организатором конкурса в период с 26 августа 2023 года по 29 августа 2023 года. В нем могут принять участие лица, отвечающие требованиям правил Конкурса и подавшие заявку, соответствующую требованиям п. 2.4. правил, на официальном сайте Конкурса. Претенденты, прошедшие отбор, приглашаются Организатором к участию в Конкурсе. В Конкурсе могут принять участие не более 14 девушек.</span></p>
                    <p><span class="font-arialBold">2.3. Ориентировочные даты проведения съемок Проекта: с 07 сентября 2023 года по 21 сентября 2023 года в г. Москве, при этом Организатор оставляет за собой право изменять даты проведения съемок, информация с изменениями будет размещена дополнительно на сайте Конкурса www.sverxnovaya.mts.ru.</span></p>
                    <p><span class="font-arialBold">2.4. Условия Участия в Конкурсе:</span></p>
                    <p><span class="font-arialBold">К участию в Проекте допускаются физически дееспособные граждане Российской Федерации женского пола (женский пол с момента рождения) (далее - Участницы), которые действуют от своего имени, обладают необходимыми и достаточными данными для приобретения и осуществления предоставляемых прав, а также добровольно, самостоятельно и лично принимают на себя все риски ответственности и последствий, связанных с участием в Проекте и соответствующие следующим требованиям:</span></p>
                    <p><span class="font-arialBold">2.4.1 Возраст: 18+;</span></p>
                    <p><span class="font-arialBold">2.4.2 Отсутствуют тяжелые хронические заболевания;</span></p>
                    <p><span class="font-arialBold">2.4.3 Отсутствуют тяжелые психиатрические расстройства /наркотическая зависимость;</span></p>
                    <p><span class="font-arialBold">2.4.4 Отсутствие судимости и не нахождение под следствием;</span></p>
                    <p><span class="font-arialBold">2.4.5 Не участвующие в фото/видео съемках эротического или порнографического характера.</span></p>
                    <p><span class="font-arialBold">2.5. Порядок регистрации в Конкурсе (подача заявок):</span></p>
                    <p><span class="font-arialBold">2.5.1 Лица, соответствующие указанным в настоящих Правилах требованиям и желающие принять участие в Конкурсе, должны зарегистрироваться на официальном сайте Конкурса и заполнить все требуемые поля анкеты (далее – Анкета), а также прикрепить видеозапись, соответствующую требованиям, установленным в настоящих Правилах.</span></p>
                    <p><span class="font-arialBold">2.5.2 Анкеты принимаются Организатором с 00 часов 00 минут 31 июля 2023 года до 23 часов 59 минут 28 августа 2023 года по московскому времени.</span></p>
                    <p><span class="font-arialBold">2.6. Требования к видеозаписи (видео-визитке)</span></p>
                    <p><span class="font-arialBold">2.6.1 Для участия в предварительном отборе Участница должна загрузить цветной или черно-белый видеоролик в формате avi, mp4, mov, размером от 1 до 100 Мб, разрешением не менее 320×240 пикселей, длительностью до 2х минут. В видеоролике Участница должна рассказать о себе, своем бизнесе/бизнес-идее .</span></p>
                    <p><span class="font-arialBold">2.7. НЕ рассматриваются видеоролики:</span></p>
                    <p><span class="font-arialBold">
                      (а) с изображением плохого качества (менее 320×240 пикселей) и/или не отвечающие требованиям, установленными настоящими Правилами;<br />
                      (б) содержащие в любой форме, прямо или косвенно, рекламу и/или любые рекламно-информационные материалы о физическом или юридическом лице (лицах), товарах, работах, услугах;<br />
                      (в) содержащие в любой форме, любым способом, в любом объеме, виде, прямо или косвенно, изображения, касающиеся темы нарушения конституционных прав и свобод личности, дискриминации по признаку религии, расы или национальности, включая любые формы выражения, связанные с подстрекательством к совершению указанных действий;<br />
                      (г) содержащие изображение любого проявления политического экстремизма, агрессии, идеологии террора, фашизма, насилия, ненависти, ксенофобии, антисемитизма, расовой, этнической и иной национальной нетерпимости, социальной сегрегации;<br />
                      (д) которые могут опорочить: государственную символику (флаги, гербы, гимны), национальную валюту РФ или иного государства, религиозные символы, объекты искусства, составляющие национальное или мировое культурное достояние,<br />
                      (е) содержащие в любой форме, любым способом, в любом объеме, виде, прямо или косвенно, изображение, содержащее признаки рекламы алкогольных напитков, табака, табачных изделий, порнографических материалов или предметов, всех видов оружия, вооружения, военной техники, продукции военного назначения, любых иных отдельных видов товаров, работ, услуг, идей, начинаний, связанных с определенными особенностями их распространения и необходимостью соблюдения положений, инструкций, правил, процедур, норм, принятых в Обществе, и/или предусмотренных действующим законодательством РФ;<br />
                      (ж) несоответствующие этическим и/или эстетическим общепринятым нормам морали и нравственности;<br />
                      (з) содержащие изображения, нарушающие запреты и ограничения, установленные Конституцией РФ, и/или противоречащие действующему законодательству РФ.
                    </span></p>
                    <p><span class="font-arialBold">2.8. Участница безвозмездно отчуждает Организатору в полном объеме исключительное право автора на загружаемые Фотографии/Видеоролики, включая, но не ограничиваясь, способами, предусмотренными статьей 1270 Гражданского кодекса РФ. Исключительные права на Фотографии/Видеоролики отчуждаются Организатору без ограничений по использованию на территории всего мира на весь срок его существования. После приобретения исключительного права на Фотографии/Видеоролики Организатор вправе по собственному усмотрению передавать права на их использование по лицензии или отчуждать исключительное право на них третьим лицам без выплаты вознаграждения Участнице.</span></p>
                    <p><span class="font-arialBold">2.9. Участница, направляя в Анкете фотографии и видеоролики для участия в конкурсе, подтверждает свое авторство на них и гарантирует, что именно она изображена на фотографиях и/или изображена на видеоролике, обладает всеми необходимыми правами и разрешениями в отношении предоставляемых Фотографий и Видеороликов, а также, что у Участницы не существует никаких обязательств перед третьими лицами (в том числе, перед авторами/ иными правообладателями). Участница соглашается с тем, что она несет полную ответственность в отношении Фотографий/Видеороликов, размещаемых на официальном сайте Конкурса.</span></p>
                    <p><span class="font-arialBold">2.10. Организатор Конкурса не несет ответственности за копирование и распространение Фотографий/ Видеороликов со страниц интернет-сайта третьими лицами. Организатор Конкурса не несет никакой ответственности за любой ущерб, понесенный Участницей вследствие участия в Конкурсе. Организатор Конкурса не имеет возможности исключить доступ к конкурсным работам третьих лиц (любых иных пользователей Интернета) и предотвратить любые нежелательные для Участницы действия со стороны указанных лиц в отношении ее конкурсных работ (копирование, скачивание, последующее изменение изображений в личных целях), в связи с чем Участница принимает на себя всю ответственность, связанную с этим.</span></p>
                    <p><span class="font-arialBold">2.11. Организатор Конкурса имеет право отклонить заявку на участие в Конкурсе без объяснения причин.</span></p>
                    <p><span class="font-arialBold">2.12. Для участия в Конкурсе не требуется внесения каких-либо денежных взносов.</span></p>

                    <p><span class="font-arialBold">3. Права и обязанности Участниц.</span></p>
                    <p><span class="font-arialBold">3.1. Права Участницы Конкурса:</span></p>
                    <p><span class="font-arialBold">3.1.1. Принимать участие в Конкурсе, в порядке, определенном настоящими Правилами.</span></p>
                    <p><span class="font-arialBold">3.1.2. Получать информацию об изменениях в Правилах Конкурса от Организатора.</span></p>
                    <p><span class="font-arialBold">3.2. Обязанности Участницы Конкурса:</span></p>
                    <p><span class="font-arialBold">3.2.1. Ознакомиться с настоящими Правилами. Выполнять все действия, связанные с участием в Конкурсе, в порядке, на условиях, в месте и сроки, установленные настоящими правилами. Участие в Конкурсе подтверждает полное согласие соответствующего Участника с условиями настоящих Правил.</span></p>
                    <p><span class="font-arialBold">3.2.2. Предоставлять Организатору Конкурса достоверные данные о себе, в том числе персональные данные, без искажения или сокрытия каких-либо фактов, а также иную информацию и/или документы, необходимые для участия в Конкурсе/получения Главного Приза или Приза зрительских симпатий.</span></p>
                    <p><span class="font-arialBold">3.2.3. Предоставить Организатору все необходимые согласия и подтверждения, необходимые для выполнения Участницами настоящих Правил, а также всех документов, подтверждающих отчуждение исключительных прав на объекты ИД, перечисленные в настоящих правилах.</span></p>
                    <p><span class="font-arialBold">3.2.4. Выполнять в период проведения Конкурса и в период проведения съемок проекта указания Организатора и/или его уполномоченных лиц.</span></p>
                    <p><span class="font-arialBold">3.2.5. В период проведения съемок проекта (далее – Период) Участницы обязуются:</span></p>
                    <p><span class="font-arialBold">
                      (а) Не вносить на съемочную площадку мобильный телефон и другие устройства с целью фотографирования и (или) получения видеоматериалов, не общаться по мобильной связи в Период, за исключением времени отдыха.<br />
                      (б) Находиться на съемочной площадке весь Период съемок.
                    </span></p>
                    <p><span class="font-arialBold">
                      3.2.6. Во время съемок запрещено:<br />
                      - Покидать/выезжать за пределы площадки. Выезд разрешен только с командой;<br />
                      - Приносить на съёмочную площадку сотовые телефоны. Либо изымаются вообще и выдаются только на вечерний звонок родственникам в присутствии координатора, либо оставляются в номере на момент съемок;<br />
                      - Выкладывать любую информацию, фото/видео со съёмочной площадки в соцсети (фото/видео выдает только МТС в соцсетях);<br />
                      - Оскорблять участников и команду проекта и сам проект, во время съемок - дисквалификация, после – штраф;<br />
                      - Разглашать конфиденциальную информацию во время съемок или после (также дисквалификация/штраф).
                    </span></p>

                    <p><span class="font-arialBold">4. Общее:</span></p>
                    <p><span class="font-arialBold">4.1.1. Организатор Конкурса оставляет за собой право в любое время дополнять и/или изменять настоящие правила, прекратить, приостановить, отменить проведение Конкурса и/или аннулировать в целом или в части, в том числе его промежуточные результаты, без уведомления Участниц. Изменения в настоящие правила Конкурса публикуются на официальном сайте Конкурса и вступают в силу с даты публикации соответствующих изменений.</span></p>
                    <p><span class="font-arialBold">4.1.2. Организатор, Участница освобождаются от ответственности за невыполнение или ненадлежащее выполнение своих обязательств, если такое невыполнение явилось результатом действия обстоятельств непреодолимой силы (форс-мажор) в том числе: войны, революции, бунта, террористического акта, действиями и решениями официальных органов и других обстоятельств, которые могут быть признаны форс-мажором в соответствии с законодательством РФ.</span></p>
                    <p><span class="font-arialBold">4.1.3. Организатор не несет ответственности за технические сбои, в том числе за задержки электронных сообщений, а также за любые иные технические сбои интернет-провайдеров, предоставляющих услуги связи Участницам Конкурса, а также за технические неполадки на официальном сайте Конкурса, в случае если они возникли не по вине Организатора, а также за неполадки и сбои в работе социальных сетей.</span></p>
                    <p><span class="font-arialBold">4.1.4. Организатор оставляет за собой право потребовать от победителя подтверждение его личности и/или персональных данных при помощи фото или документов, удостоверяющих личность. В случае, если фото и/или документы не будут предоставлены, организатор имеет право лишить победителя права на получение призов.</span></p>
                    <p><span class="font-arialBold">4.1.5. Любая попытка мошенничества и/или подтасовки данных, будет рассматриваться в контексте административного и уголовного кодексов Российской Федерации.</span></p>
                    <p><span class="font-arialBold">4.1.6. Организатор оставляет за собой право потребовать от победителя подписания официального документа о получении им приза.</span></p>
                    <p><span class="font-arialBold">4.1.7. Организатор конкурса оставляет за собой право изменить правила конкурса или отменить его без уведомления пользователей.</span></p>
                    <p><span class="font-arialBold">4.1.8. Организатор конкурса и компании-партнеры несут всю ответственность за организацию и проведение конкурса, предусмотренную законом.</span></p>

                    <p><span class="font-arialBold">5. Условия обработки персональных данных Участницы конкурса</span></p>
                    <p><span class="font-arialBold">5.1. Участница конкурса, акцептовав настоящие Правила, действуя свободно, своей волей и в своем интересе выражает свое согласие Организатору Конкурса на обработку всех своих персональных данных.</span></p>
                    <p><span class="font-arialBold">
                      5.2. Все собранные данные пользователей могут быть использованные организатором конкурса в следующих каналах:<br />
                      1. Официальный сайт проекта.<br />
                      2. Любые другие сайты и страницы в Интернете, принадлежащие или имеющие отношение к организатору конкурса, а так же ПАО «МТС».<br />
                      3. Официальные группы проекта в социальных сетях.<br />
                      4. На любых других страницах в социальных сетях, принадлежащих или имеющих отношение к организатору конкурса или ПАО «МТС».<br />
                      5. Любых других СМИ, включая телевизионные каналы, прессу, радио.
                    </span></p>
                    <p><span class="font-arialBold">5.3. Регистрируясь, пользователи соглашаются с тем, что их данные могут быть использованы организатором для контактов, касающихся других продуктов и услуг организатора конкурса.</span></p>
                    <p><span class="font-arialBold">6. Порядок определения Победителей Конкурса.</span></p>
                    <p><span class="font-arialBold">6.1. Победитель 1 определяется на основании решения экспертного жюри. Решение жюри принимается коллегиально большинством голосов по итогам конкурсных испытаний. Состав членов жюри согласовывается с Организатором.</span></p>
                    <p><span class="font-arialBold">6.2. Победитель 2 определяется на основании голосования на официальном сайте Конкурса. Голосование проводится с 21 сентября 2023 года по 01 декабря 2023 года. Участница, набравшая большинство голосов на 01 декабря 2023 года на момент 23 часов 59 минут по московскому времени, признается Победителем 2.</span></p>

                    <p><span class="font-arialBold">7. Заключительные положения.</span></p>
                    <p><span class="font-arialBold">7.1. Информирование о проведении Конкурса и его условиях производится посредством размещения настоящих Правил на официальном интернет-сайте Конкурса www.sverxnovaya.mts.ru.</span></p>
                    <p><span class="font-arialBold">7.2. Решения Организатора Конкурса по всем вопросам, связанным с проведением Конкурса, являются окончательными и не подлежащими пересмотру или оспариванию Участницами.</span></p>
                    <p><span class="font-arialBold">7.3. Организатор Конкурса оставляет за собой право размещать дополнительную информацию о Конкурсе в период проведения Конкурса и после завершения Конкурса на официальном интернет-сайте Конкурса www.sverxnovaya.mts.ru.</span></p>
                    <p><span class="font-arialBold">7.4. Факт участия Участницы в Конкурсе означает полное согласие данной Участницы с условиями настоящих Правил Конкурса.</span></p>
                    <p><span class="font-arialBold">7.5. Термины, употребляемые в настоящих правилах, относятся исключительно к настоящему Конкурсу.</span></p>
                    <p><span class="font-arialBold">7.6. Конкурс, настоящие правила Конкурса, а также любая деятельность, связанная с Конкурсом, регулируются законодательством РФ.</span></p>
                    <p><span class="font-arialBold">7.7. Правовое регулирование Конкурса находится вне рамок гл. 57 Гражданского кодекса РФ и осуществляется общими нормами об обязательствах.</span></p>
                    <p><span class="font-arialBold">7.8. Конкурс является состязанием, проводимым ООО «Хьюман Филмс» в рамках предпринимательской деятельности и представляющее собой зрелищное мероприятие для публики.</span></p>
                    <p><span class="font-arialBold">7.9. Все спорные вопросы, касающиеся настоящего Конкурса, регулируются действующим законодательством РФ.</span></p>
                    <p><span class="font-arialBold">7.10. Победитель 1 и Победитель 2 Конкурса для получения Главного Приза и Приза зрительских симпатий предоставляют перечень сведений и копий документов, удостоверяющих личность (скан-копий, фотокопий), необходимых для организации вручения и/или высылки приза/призов Победителям, и исполнения Организатором обязанностей налогового агента.</span></p>
                    <p><span class="font-arialBold">
                      7.11. Победитель 1 и Победитель 2 Конкурса настоящим уведомляются, что:<br />
                      - в соответствии со ст. 207 НК РФ физические лица, получающие доходы, в том числе в натуральной форме, от источников в РФ являются налогоплательщиками налога на доходы физических лиц (НДФЛ). Порядок уплаты НДФЛ регулируется главой 23 НК РФ. Налоговым периодом по НДФЛ признается календарный год.<br />
                      - Организатор самостоятельно осуществляет подачу сведений о выгодоприобретателе – Победителе 1 Конкурса, которому был выдан Главный Приз, в налоговый орган, а также является Налоговым Агентом Победителя 1 Конкурса.<br />
                      - При получении Главного Приза Победитель 1 Конкурса уведомлен о том, что налоговым агентом (Организатор) с суммы полученного дохода будет удержан налог на доходы физических лиц по ставке 35% (на основании п. 2 ст. 224 НК РФ).<br />
                      - при получении Приза зрительских симпатий Победитель 2 Конкурса уведомлен о том, что налоговым агентом (Организатор) с суммы полученного дохода будет удержан налог на доходы физических лиц по ставке 35% (на основании п. 2 ст. 224 НК РФ).»
                    </span></p>

                    <div class="flex justify-end mt-100">
                      <div class="w-320 text-sm">
                        <span class="font-arialBold"><b>ООО «Хьюман Филмс»</b></span><br>
                        <span>Адрес: 115093, г. Москва, ул. Большая </span><br>
                        <span>Серпуховская, д. 31, корп. 14, каб.1</span><br>
                        <span>ОГРН 1187746861884</span><br>
                        <span>ИНН 7725498160</span><br>
                        <span>КПП 770501001</span><br>
                        <span>Р/С: 40702810202370004021 в АО «АЛЬФА-БАНК», </span><br>
                        <span>г. Москва</span><br>
                        <span>К/С 30101810200000000593</span><br>
                        <span>БИК 044525593</span><br>
                      </div>
                    </div>
                </section>
            </template>        
        </screen-wrapper>
        </div>
    </div>      
</template> 

<script>
//Components
import BaseScreenWrapper from "@/components/views/home/components/BaseScreenWrapper";
import { isMobile } from "@/helpers/is-mobile";

export default {
  name: "DetailsComponent",
  components: {
    "screen-wrapper": BaseScreenWrapper,
  },
  computed: {
    IMGS() {
      return {
        title: require("./assets/title.svg"),
        arrow_back: require("./assets/arrow-back.svg"),
      };
    },
    isMobile() {
      return isMobile();
    },
  },
  methods: {
    onBack() {
      if (this.isMobile) {
        this.$router.push("/");
      } else {
        window.open("/", "_self");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.details-format {
    p {
        display: block;
        margin-bottom: 20px;
    }
}
</style>